import '../shared/sharedStyles.css';
import { useContext, useState } from 'react';
import { useMediaQuery } from 'react-responsive';

import MainContext from '../shared/context';

import { firestore } from '../firebase/config';

import Cookies from 'universal-cookie';

const cookies = new Cookies();

export const EmailFormOverlay = ({ show, close }) => {
    const { setEmailSubmitted, selectedLanguage } = useContext(MainContext);

    const isMobile = useMediaQuery({ query: `(max-width: 760px)` });
    const [email, setEmail] = useState('');

    const handleChangeEmail = (event) => {
        setEmail(String(event.target.value).toUpperCase());
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (email !== '') {
            const emailsCol = firestore.collection(firestore.db, 'emails');
            const emailsSnapshot = await firestore.getDocs(emailsCol);
            const emailsList = emailsSnapshot.docs.map((doc) => doc.data().email);
            const emailsLenght = emailsList.length;

            const fixedEmail = email.replace(' ', '').toLowerCase();

            const newEmail = { email: fixedEmail };

            if (!emailsList.includes(fixedEmail)) {
                await firestore.setDoc(firestore.doc(firestore.db, 'emails', String(Number(emailsLenght + 1))), newEmail);
            }
            setEmailSubmitted(true);
            close();
            cookies.set('cln-avv-vos-email-submitted', 'true', { path: '/', maxAge: 10000000 });
        }
    };

    return (
        <div className={isMobile ? 'overlay-container-mobile' : 'overlay-container'} style={{ display: show ? 'flex' : 'none', paddingTop: 40 }}>
            {selectedLanguage === 'it' ? (
                <p className={isMobile ? 'overlay-description-mobile' : 'overlay-description'} style={{ marginBottom: 20 }}>
                    INSERISCI LA TUA MAIL
                    <br />
                    PER ACCEDERE AL CALENDARIO
                </p>
            ) : (
                <p className={isMobile ? 'overlay-description-mobile' : 'overlay-description'} style={{ marginBottom: 20 }}>
                    INSERT YOUR EMAIL
                    <br />
                    TO ACCESS THE CALENDAR
                </p>
            )}
            <form className='overlay-form' onSubmit={handleSubmit}>
                <input className='overlay-input' type='email' placeholder='EMAIL' value={email} onChange={handleChangeEmail} />
                <input className='overlay-button' type='submit' value={selectedLanguage === 'it' ? 'INVIO' : 'SEND'} />
            </form>
        </div>
    );
};
