// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getFirestore, collection, getDocs, getDoc, doc, setDoc, deleteDoc } from 'firebase/firestore/lite';
import { getAuth, signInWithEmailAndPassword, onAuthStateChanged, signOut } from 'firebase/auth';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: 'AIzaSyBoSWIcaf_R8rfXZy8g_l8rBbaMo3yfcYA',
    authDomain: 'cln-avv-vos.firebaseapp.com',
    projectId: 'cln-avv-vos',
    storageBucket: 'cln-avv-vos.appspot.com',
    messagingSenderId: '1075507001869',
    appId: '1:1075507001869:web:ff60e7dfb337a5aaacb344',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const firestore = {
    db: getFirestore(app),
    collection,
    getDoc,
    getDocs,
    doc,
    setDoc,
    deleteDoc,
};

const auth = {
    getAuth,
    signInWithEmailAndPassword,
    onAuthStateChanged,
    signOut,
};

export { firestore, auth };
