import './shared/sharedStyles.css';
import { useState, useEffect } from 'react';

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import MainContext from './shared/context';

import { firestore, auth } from './firebase/config';

import MainPage from './pages/mainPage';
import AdminPageLogIn from './pages/adminPageLogIn';
import AdminPageLogOut from './pages/adminPageLogOut';

import Cookies from 'universal-cookie';

const cookies = new Cookies();

function App() {
    const [user, setUser] = useState(null);

    const [cellClicked, setCellClicked] = useState(false);
    const [cellClickedId, setCellClickedId] = useState(false);

    const [cellClickedInnerText, setCellClickedInnerText] = useState('');
    const [openedCells, setOpenedCells] = useState(cookies.get('cln-avv-vos-opened-cells'));
    const [emailSubmitted, setEmailSubmitted] = useState(cookies.get('cln-avv-vos-email-submitted') === 'true' ? true : false);

    const [emails, setEmails] = useState([]);
    const [couponCodes, setCouponCodes] = useState([]);

    const [selectedLanguage, setSelectedLanguage] = useState('it');

    useEffect(() => {
        const authGot = auth.getAuth();
        auth.onAuthStateChanged(authGot, (user) => {
            if (user) {
                // User is signed in, see docs for a list of available properties
                // https://firebase.google.com/docs/reference/js/firebase.User
                const uid = user.uid;
                setUser(uid);
                // ...
            } else {
                // User is signed out
                setUser(null);
                // ...
            }
        });
        const emailsRef = firestore.collection(firestore.db, 'emails');
        const couponCodesRef = firestore.collection(firestore.db, 'couponCodes');
        firestore.getDocs(couponCodesRef).then((couponCodesSnapshot) => {
            const couponsList = couponCodesSnapshot.docs.map((doc) => ({
                id: doc.id,
                title: doc.data().title,
                titleEN: doc.data().titleEN,
                text: doc.data().text,
                textEN: doc.data().textEN,
                isCode: doc.data().isCode,
                url: doc.data().url,
            }));
            setCouponCodes(couponsList);
            firestore.getDocs(emailsRef).then((emailsSnapshot) => {
                const emailsList = emailsSnapshot.docs.map((doc) => doc.data().email);
                setEmails(emailsList);
            });
        });
    }, []);

    useEffect(() => {
        const openedCells = cookies.get('cln-avv-vos-opened-cells');
        setOpenedCells(openedCells);
    }, [cellClickedInnerText]);

    return (
        <MainContext.Provider
            value={{
                user,
                setUser,
                cellClicked,
                setCellClicked,
                cellClickedId,
                setCellClickedId,
                emails,
                couponCodes,
                cellClickedInnerText,
                setCellClickedInnerText,
                openedCells,
                setOpenedCells,
                emailSubmitted,
                setEmailSubmitted,
                selectedLanguage,
                setSelectedLanguage,
            }}>
            <Router>
                <div className='App'>
                    <Routes>
                        {user ? <Route exact path='/adminLogOut' element={<AdminPageLogOut />} /> : <Route exact path='/admin' element={<AdminPageLogIn />} />}
                        <Route exact path='/' element={<MainPage />} />
                    </Routes>
                </div>
            </Router>
        </MainContext.Provider>
    );
}

export default App;
