import { useContext } from 'react';

import { useNavigate } from 'react-router-dom';

import MainContext from '../shared/context';

import { auth } from '../firebase/config';

function AdminPageLogOut() {
    const { setUser, emails } = useContext(MainContext);

    const navigate = useNavigate();

    const handleSubmit = (event) => {
        event.preventDefault();
        const authGot = auth.getAuth();
        auth.signOut(authGot)
            .then(() => {
                // Sign-out successful.
                setUser(null);
                navigate('/admin');
            })
            .catch((error) => {
                // An error happened.
                console.error(error);
            });
    };

    return (
        <div>
            <form onSubmit={handleSubmit}>
                <input type='submit' value='LOG OUT' />
            </form>
            {emails.map((email) => (
                <p>{email}</p>
            ))}
        </div>
    );
}

export default AdminPageLogOut;
