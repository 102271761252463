import '../shared/sharedStyles.css';
import { useContext } from 'react';

import MainContext from '../shared/context';

export const BackButton = () => {
    const { selectedLanguage } = useContext(MainContext);

    const handleOnClick = (event) => {
        event.preventDefault();
        window.open('https://visionofsuper.com/', '_self');
    };

    return (
        <div className='back-button-container' onClick={handleOnClick}>
            <p className='back-button-text'>{selectedLanguage === 'it' ? 'RITORNA ALLO SHOP' : 'RETURN TO SHOP'}</p>
        </div>
    );
};
