import { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import MainContext from '../shared/context';

import { auth } from '../firebase/config';

function AdminPageLogIn() {
    const { setUser } = useContext(MainContext);

    const navigate = useNavigate();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    const handleSubmit = (event) => {
        event.preventDefault();
        const authGot = auth.getAuth();
        auth.signInWithEmailAndPassword(authGot, email.toLowerCase(), password)
            .then((userCredential) => {
                // Signed in
                const user = userCredential.user;
                setUser(user);
                navigate('/adminLogOut');
                // ...
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                console.log('errorCode:', errorCode);
                if (errorCode === 'auth/user-not-found') {
                    setError('Utente non trovato');
                } else if (errorCode === 'auth/wrong-password') {
                    setError('Password errata');
                } else {
                    setError(errorMessage);
                }
            });
    };

    const handleChangeEmail = (event) => {
        setEmail(String(event.target.value));
    };

    const handleChangePassword = (event) => {
        setPassword(String(event.target.value));
    };

    return (
        <div>
            <form onSubmit={handleSubmit}>
                <div>
                    <input type='email' placeholder='Email' value={email} onChange={handleChangeEmail} />
                    <input type='password' placeholder='Password' value={password} onChange={handleChangePassword} />
                    <p>{error}</p>
                </div>
                <input type='submit' value='LOG IN' />
            </form>
        </div>
    );
}

export default AdminPageLogIn;
