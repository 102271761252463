import '../shared/sharedStyles.css';
import { useContext, useState, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';

import MainContext from '../shared/context';

export const MyOverlay = ({ show, close, overlayImage }) => {
    const { setCellClicked, cellClickedInnerText, setCellClickedInnerText, couponCodes, selectedLanguage } = useContext(MainContext);

    const isMobile = useMediaQuery({ query: `(max-width: 760px)` });

    const [cellText, setCellText] = useState('');
    const [cellTitle, setCellTitle] = useState('');
    const [cellIsCode, setCellIsCode] = useState('');
    const [cellUrl, setCellUrl] = useState('');

    useEffect(() => {
        if (couponCodes && couponCodes.length > 0) {
            const couponFound = couponCodes.find((e) => e.id === cellClickedInnerText);

            if (couponFound) {
                setCellTitle(selectedLanguage === 'it' ? couponFound.title : couponFound.titleEN);
                setCellText(selectedLanguage === 'it' ? couponFound.text : couponFound.textEN);
                setCellIsCode(couponFound.isCode);
                setCellUrl(couponFound.url);
            }
        }
    }, [couponCodes, cellClickedInnerText, selectedLanguage]);

    const handleBoxClick = () => {
        if (cellUrl && cellUrl !== '') {
            window.open(cellUrl, '_blank');
        } else if (cellIsCode && (!cellUrl || cellUrl === '')) {
            const el = document.createElement('textarea');
            el.value = cellText;
            document.body.appendChild(el);
            el.select();
            document.execCommand('copy');
            document.body.removeChild(el);
        }
    };

    const handleClose = () => {
        setCellClicked(false);
        setCellClickedInnerText('');
        close();
    };

    return (
        <div className={isMobile ? 'overlay-container-mobile' : 'overlay-container'} style={{ display: show ? 'flex' : 'none' }}>
            <p className={isMobile ? 'close-icon-mobile' : 'close-icon'} onClick={handleClose}>
                X
            </p>
            <img src={overlayImage} alt='overlay' className={isMobile ? 'overlay-circle-mobile' : 'overlay-circle'} />
            <h1 className={isMobile ? 'overlay-title-mobile' : 'overlay-title'}>{cellTitle}</h1>
            <p className={isMobile ? 'overlay-description-mobile' : 'overlay-description'}>
                {selectedLanguage === 'it' ? "PREMI SUL TESTO PER SCOPRIRE DI PIU'" : 'CLICK ON THE TEXT TO FIND OUT MORE'}
            </p>
            <div className={isMobile ? 'overlay-text-container-mobile' : 'overlay-text-container'} onClick={handleBoxClick}>
                <h2 className={isMobile ? 'overlay-text-mobile' : 'overlay-text'}>{cellText}</h2>
            </div>
        </div>
    );
};
