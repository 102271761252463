import '../shared/sharedStyles.css';
import { useState, useEffect, useContext } from 'react';

import MainContext from '../shared/context';

import { CalCell } from '../components/calCell';
import { MyOverlay } from '../components/myOverlay';
import { EmailFormOverlay } from '../components/emailFormOverlay';
import { BackButton } from '../components/backButton';
import { useMediaQuery } from 'react-responsive';

import logo from '../assets/vos-logo-w.png';

import candyBlue from '../assets/videos/CANDY/BLUE.mp4';
import candyGreen from '../assets/videos/CANDY/GREEN.mp4';
import candyOrange from '../assets/videos/CANDY/ORANGE.mp4';
import candyPink from '../assets/videos/CANDY/PINK.mp4';
import candyRed from '../assets/videos/CANDY/RED.mp4';

import flameBlue from '../assets/videos/FLAME/BLUE.mp4';
import flameGreen from '../assets/videos/FLAME/GREEN.mp4';
import flameOrange from '../assets/videos/FLAME/ORANGE.mp4';
import flamePink from '../assets/videos/FLAME/PINK.mp4';
import flameRed from '../assets/videos/FLAME/RED.mp4';

import pandaBlue from '../assets/videos/PANDA/BLUE.mp4';
import pandaGreen from '../assets/videos/PANDA/GREEN.mp4';
import pandaOrange from '../assets/videos/PANDA/ORANGE.mp4';
import pandaPink from '../assets/videos/PANDA/PINK.mp4';
import pandaRed from '../assets/videos/PANDA/RED.mp4';

import overlayImage1 from '../assets/overlay-images/1.jpg';
import overlayImage2 from '../assets/overlay-images/2.jpg';
import overlayImage3 from '../assets/overlay-images/3.jpg';
import overlayImage4 from '../assets/overlay-images/4.jpg';
import overlayImage5 from '../assets/overlay-images/5.jpg';
import overlayImage6 from '../assets/overlay-images/6.jpg';
import overlayImage7 from '../assets/overlay-images/7.jpg';
import overlayImage8 from '../assets/overlay-images/8.jpg';
import overlayImage9 from '../assets/overlay-images/9.jpg';
import overlayImage10 from '../assets/overlay-images/10.jpg';
import overlayImage11 from '../assets/overlay-images/11.jpg';
import overlayImage12 from '../assets/overlay-images/12.jpg';
import overlayImage13 from '../assets/overlay-images/13.jpg';
import overlayImage14 from '../assets/overlay-images/14.jpg';
import overlayImage15 from '../assets/overlay-images/15.jpg';
import overlayImage16 from '../assets/overlay-images/16.jpg';
import overlayImage17 from '../assets/overlay-images/17.jpg';
import overlayImage18 from '../assets/overlay-images/16.jpg';
import overlayImage19 from '../assets/overlay-images/19.jpg';
import overlayImage20 from '../assets/overlay-images/16.jpg';
import overlayImage21 from '../assets/overlay-images/21.jpg';
import overlayImage22 from '../assets/overlay-images/22.jpg';
import overlayImage23 from '../assets/overlay-images/21.jpg';
import overlayImage24 from '../assets/overlay-images/24.jpg';

function MainPage() {
    const { cellClickedInnerText, emailSubmitted, selectedLanguage, setSelectedLanguage } = useContext(MainContext);
    const isMobile = useMediaQuery({ query: `(max-width: 760px)` });
    const [showEmailFormOverlay, setShowEmailFormOverlay] = useState(false);
    const [showOverlay, setShowOverlay] = useState(false);
    const [selectedDate, setSelectedDate] = useState('');

    useEffect(() => {
        if (emailSubmitted === true) {
            setShowEmailFormOverlay(false);
        } else {
            setShowEmailFormOverlay(true);
        }
    }, [emailSubmitted]);

    const handleCellClick = (date) => {
        setSelectedDate(date);
        setShowOverlay(true);
    };

    const closeEmailFormOverlay = () => setShowEmailFormOverlay(false);
    const closeOverlay = () => setShowOverlay(false);

    const getOverlayImage = (innerText) => {
        switch (innerText) {
            case '1':
                return overlayImage1;
            case '2':
                return overlayImage2;
            case '3':
                return overlayImage3;
            case '4':
                return overlayImage4;
            case '5':
                return overlayImage5;
            case '6':
                return overlayImage6;
            case '7':
                return overlayImage7;
            case '8':
                return overlayImage8;
            case '9':
                return overlayImage9;
            case '10':
                return overlayImage10;
            case '11':
                return overlayImage11;
            case '12':
                return overlayImage12;
            case '13':
                return overlayImage13;
            case '14':
                return overlayImage14;
            case '15':
                return overlayImage15;
            case '16':
                return overlayImage16;
            case '17':
                return overlayImage17;
            case '18':
                return overlayImage18;
            case '19':
                return overlayImage19;
            case '20':
                return overlayImage20;
            case '21':
                return overlayImage21;
            case '22':
                return overlayImage22;
            case '23':
                return overlayImage23;
            case '24':
                return overlayImage24;

            default:
                return overlayImage1;
        }
    };

    const handleLanguageChange = (e) => {
        setSelectedLanguage(e.target.value);
    };

    return (
        <>
            {isMobile ? (
                <div className='master-container'>
                    {/* <div className='header-container' style={{ justifyContent: 'center', flexDirection: 'column', padding: 0 }}>
                        <img src={logo} alt='logo' className='header-logo' />
                        <p className='header-text' style={{ textAlign: 'center', margin: 0 }}>
                            ADVENT CALENDAR
                        </p>
                    </div> */}
                    <div className='header-container'>
                        <div className='header-title-container' style={{ justifyContent: 'center', flexDirection: 'column', padding: 0 }}>
                            <img src={logo} alt='logo' className='header-logo' />
                            <p className='header-text' style={{ textAlign: 'center', margin: 0 }}>
                                ADVENT CALENDAR
                            </p>
                        </div>
                        <select className='header-select' value={selectedLanguage} name='languages' id='languages' onChange={handleLanguageChange}>
                            <option value='it'>Italiano</option>
                            <option value='en'>English</option>
                        </select>
                    </div>
                    <div className='grid-container' style={{ pointerEvents: showOverlay || showEmailFormOverlay ? 'none' : null }}>
                        <div className='column'>
                            <CalCell innerText='1' height={150} onClick={() => handleCellClick('1 DICEMBRE')} cellVideo={candyBlue} />
                            <CalCell innerText='3' height={225} onClick={() => handleCellClick('3 DICEMBRE')} cellVideo={pandaBlue} />
                            <CalCell innerText='5' height={200} onClick={() => handleCellClick('5 DICEMBRE')} cellVideo={flameGreen} />
                            <CalCell innerText='7' height={200} onClick={() => handleCellClick('7 DICEMBRE')} cellVideo={candyOrange} />
                            <CalCell innerText='9' height={275} onClick={() => handleCellClick('9 DICEMBRE')} cellVideo={pandaOrange} />
                            <CalCell innerText='11' height={150} onClick={() => handleCellClick('11 DICEMBRE')} cellVideo={flamePink} />
                            <CalCell innerText='13' height={200} onClick={() => handleCellClick('13 DICEMBRE')} cellVideo={candyRed} />
                            <CalCell innerText='15' height={200} onClick={() => handleCellClick('15 DICEMBRE')} cellVideo={pandaRed} />
                            <CalCell innerText='17' height={250} onClick={() => handleCellClick('17 DICEMBRE')} cellVideo={flameBlue} />
                            <CalCell innerText='19' height={300} onClick={() => handleCellClick('19 DICEMBRE')} cellVideo={candyGreen} />
                            <CalCell innerText='21' height={150} onClick={() => handleCellClick('21 DICEMBRE')} cellVideo={pandaGreen} />
                            <CalCell innerText='23' height={200} onClick={() => handleCellClick('23 DICEMBRE')} cellVideo={flameOrange} />
                        </div>
                        <div className='column'>
                            <CalCell innerText='2' height={300} onClick={() => handleCellClick('2 DICEMBRE')} cellVideo={flameBlue} />
                            <CalCell innerText='4' height={150} onClick={() => handleCellClick('4 DICEMBRE')} cellVideo={candyGreen} />
                            <CalCell innerText='6' height={225} onClick={() => handleCellClick('6 DICEMBRE')} cellVideo={pandaGreen} />
                            <CalCell innerText='8' height={125} onClick={() => handleCellClick('8 DICEMBRE')} cellVideo={flameOrange} />
                            <CalCell innerText='10' height={275} onClick={() => handleCellClick('10 DICEMBRE')} cellVideo={candyPink} />
                            <CalCell innerText='12' height={150} onClick={() => handleCellClick('12 DICEMBRE')} cellVideo={pandaPink} />
                            <CalCell innerText='14' height={350} onClick={() => handleCellClick('14 DICEMBRE')} cellVideo={flameRed} />
                            <CalCell innerText='16' height={150} onClick={() => handleCellClick('16 DICEMBRE')} cellVideo={candyBlue} />
                            <CalCell innerText='18' height={250} onClick={() => handleCellClick('18 DICEMBRE')} cellVideo={pandaBlue} />
                            <CalCell innerText='20' height={150} onClick={() => handleCellClick('20 DICEMBRE')} cellVideo={flameGreen} />
                            <CalCell innerText='22' height={377} onClick={() => handleCellClick('22 DICEMBRE')} cellVideo={candyOrange} />
                        </div>
                    </div>
                    <CalCell
                        innerText='24'
                        height={200}
                        lastCell={true}
                        onClick={() => handleCellClick('24 DICEMBRE')}
                        style={{ pointerEvents: showOverlay || showEmailFormOverlay ? 'none' : null }}
                        cellVideo={pandaOrange}
                    />
                    <MyOverlay show={showOverlay} date={selectedDate} close={closeOverlay} overlayImage={getOverlayImage(cellClickedInnerText)} />
                    <EmailFormOverlay show={showEmailFormOverlay} close={closeEmailFormOverlay} />
                    <BackButton />
                </div>
            ) : (
                <div className='master-container'>
                    <div className='header-container'>
                        <div className='header-title-container'>
                            <img src={logo} alt='logo' className='header-logo' />
                            <p className='header-text'>ADVENT CALENDAR</p>
                        </div>
                        <select className='header-select' value={selectedLanguage} name='languages' id='languages' onChange={handleLanguageChange}>
                            <option value='it'>Italiano</option>
                            <option value='en'>English</option>
                        </select>
                    </div>
                    <div className='grid-container' style={{ pointerEvents: showOverlay || showEmailFormOverlay ? 'none' : null }}>
                        <div className='column'>
                            <CalCell innerText='1' height={250} onClick={() => handleCellClick('1 DICEMBRE')} cellVideo={candyBlue} />
                            <CalCell innerText='4' height={375} onClick={() => handleCellClick('4 DICEMBRE')} cellVideo={candyGreen} />
                            <CalCell innerText='7' height={525} onClick={() => handleCellClick('7 DICEMBRE')} cellVideo={candyOrange} />
                            <CalCell innerText='10' height={375} onClick={() => handleCellClick('10 DICEMBRE')} cellVideo={candyPink} />
                            <CalCell innerText='13' height={500} onClick={() => handleCellClick('13 DICEMBRE')} cellVideo={candyRed} />
                            <CalCell innerText='16' height={275} onClick={() => handleCellClick('16 DICEMBRE')} cellVideo={candyBlue} />
                            <CalCell innerText='19' height={400} onClick={() => handleCellClick('19 DICEMBRE')} cellVideo={candyGreen} />
                            <CalCell innerText='22' height={375} onClick={() => handleCellClick('22 DICEMBRE')} cellVideo={candyOrange} />
                        </div>
                        <div className='column'>
                            <CalCell innerText='2' height={500} onClick={() => handleCellClick('2 DICEMBRE')} cellVideo={flameBlue} />
                            <CalCell innerText='5' height={250} onClick={() => handleCellClick('5 DICEMBRE')} cellVideo={flameGreen} />
                            <CalCell innerText='8' height={400} onClick={() => handleCellClick('8 DICEMBRE')} cellVideo={flameOrange} />
                            <CalCell innerText='11' height={250} onClick={() => handleCellClick('11 DICEMBRE')} cellVideo={flamePink} />
                            <CalCell innerText='14' height={525} onClick={() => handleCellClick('14 DICEMBRE')} cellVideo={flameRed} />
                            <CalCell innerText='17' height={275} onClick={() => handleCellClick('17 DICEMBRE')} cellVideo={flameBlue} />
                            <CalCell innerText='20' height={650} onClick={() => handleCellClick('20 DICEMBRE')} cellVideo={flameGreen} />
                            <CalCell innerText='23' height={225} onClick={() => handleCellClick('23 DICEMBRE')} cellVideo={flameOrange} />
                        </div>
                        <div className='column'>
                            <CalCell innerText='3' height={375} onClick={() => handleCellClick('3 DICEMBRE')} cellVideo={pandaBlue} />
                            <CalCell innerText='6' height={500} onClick={() => handleCellClick('6 DICEMBRE')} cellVideo={pandaGreen} />
                            <CalCell innerText='9' height={275} onClick={() => handleCellClick('9 DICEMBRE')} cellVideo={pandaOrange} />
                            <CalCell innerText='12' height={650} onClick={() => handleCellClick('12 DICEMBRE')} cellVideo={pandaPink} />
                            <CalCell innerText='15' height={400} onClick={() => handleCellClick('15 DICEMBRE')} cellVideo={pandaRed} />
                            <CalCell innerText='18' height={225} onClick={() => handleCellClick('18 DICEMBRE')} cellVideo={pandaBlue} />
                            <CalCell innerText='21' height={652} onClick={() => handleCellClick('21 DICEMBRE')} cellVideo={pandaGreen} />
                        </div>
                    </div>
                    <CalCell
                        innerText='24'
                        height={400}
                        lastCell={true}
                        onClick={() => handleCellClick('24 DICEMBRE')}
                        style={{ pointerEvents: showOverlay || showEmailFormOverlay ? 'none' : null }}
                        cellVideo={pandaOrange}
                    />
                    <MyOverlay show={showOverlay} date={selectedDate} close={closeOverlay} overlayImage={getOverlayImage(cellClickedInnerText)} />
                    <EmailFormOverlay show={showEmailFormOverlay} close={closeEmailFormOverlay} />
                    <BackButton />
                </div>
            )}
        </>
    );
}

export default MainPage;
