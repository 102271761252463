import '../shared/sharedStyles.css';
import { useRef, useContext, useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import MainContext from '../shared/context';

import moment from 'moment';

import Cookies from 'universal-cookie';

const cookies = new Cookies();

export const CalCell = ({ backgroundColor, innerText, height, lastCell = false, onClick, style, cellVideo }) => {
    const { cellClicked, setCellClicked, cellClickedId, setCellClickedId, setCellClickedInnerText, openedCells } = useContext(MainContext);

    const isMobile = useMediaQuery({ query: `(max-width: 760px)` });
    const backItemRef = useRef(null);
    const videoRef = useRef(null);

    const currentDateToMidnight = moment(`${moment().date()}/12/2021`, 'DD/MM/YYYY').valueOf();
    const currentDate = moment().valueOf();
    const cellDate = moment(`${innerText}/12/2021`, 'D/MM/YYYY').valueOf();
    const cellIsValid = currentDate >= cellDate ? true : false;

    const [cellAlreadyOpened, setCellAlreadyOpened] = useState(false);

    useEffect(() => {
        if (cellClicked && cellClickedId !== innerText) {
            backItemRef.current.style.width = '0';
            videoRef.current.style.opacity = '0';
        }
    }, [cellClicked, cellClickedId, innerText]);

    useEffect(() => {
        if (openedCells && openedCells.length > 0 && openedCells.includes(innerText)) {
            setCellAlreadyOpened(true);
        } else {
            setCellAlreadyOpened(false);
        }
    }, [openedCells, innerText]);

    const handleOnClick = () => {
        if (cellIsValid) {
            if (isMobile && !cellClicked && !cellAlreadyOpened) {
                setCellClicked(true);
                setCellClickedId(innerText);
                backItemRef.current.style.width = lastCell ? '100%' : 'calc(100%/2)';
                videoRef.current.play();
                videoRef.current.style.opacity = '1';
                setTimeout(() => {
                    setCellClickedInnerText(innerText);
                    onClick();
                }, 3000);
            } else if (isMobile) {
                setCellClicked(true);
                setCellClickedId(innerText);
                setCellClickedInnerText(innerText);
                onClick();
            } else if (!isMobile) {
                setCellClickedInnerText(innerText);
                onClick();
            }
            let openedCells = cookies.get('cln-avv-vos-opened-cells');
            if (!Array.isArray(openedCells)) {
                openedCells = [];
            }
            const newOpenedCells = [...openedCells, innerText];
            cookies.set('cln-avv-vos-opened-cells', JSON.stringify(newOpenedCells), { path: '/', maxAge: 10000000 });
        }
    };

    return (
        <div className='cell' style={{ ...style, backgroundColor: backgroundColor, height: height, borderBottomWidth: lastCell ? 1 : 0 }} onClick={handleOnClick}>
            <div className='cell-to-transform' style={{ height: height, width: lastCell ? '100%' : 'calc(100%/3)' }}>
                <b
                    className={cellAlreadyOpened ? 'cell-text-already-opened' : 'cell-text'}
                    style={{
                        WebkitTextStroke:
                            cellIsValid && !cellAlreadyOpened && currentDateToMidnight !== cellDate
                                ? '1px rgba(0, 0, 0, 1)'
                                : cellIsValid && !cellAlreadyOpened && currentDateToMidnight === cellDate
                                ? '1px #ea3323'
                                : cellAlreadyOpened && currentDateToMidnight !== cellDate
                                ? '4.5px rgba(0, 0, 0, 1)'
                                : cellAlreadyOpened && currentDateToMidnight === cellDate
                                ? '4.5px #ea3323'
                                : '1px rgba(0, 0, 0, 0.5)',
                    }}>
                    {innerText}
                </b>
            </div>
            <div
                ref={backItemRef}
                className={
                    lastCell && cellIsValid
                        ? 'last-back-cell'
                        : lastCell && !cellIsValid
                        ? 'last-back-cell-not-valid'
                        : isMobile
                        ? 'back-cell-mobile'
                        : cellIsValid
                        ? 'back-cell'
                        : 'back-cell-not-valid'
                }
                style={{ height: height, display: cellAlreadyOpened ? 'none' : null }}
            />
            <video
                muted
                webkit-playsinline='true'
                playsinline='true'
                ref={videoRef}
                height={height}
                onMouseOver={(event) => event.target.play()}
                onMouseOut={(event) => event.target.load()}
                className={
                    lastCell && cellIsValid
                        ? 'back-cell-inner-item-last-cell'
                        : lastCell && cellIsValid
                        ? 'back-cell-inner-item-last-cell-not-valid'
                        : isMobile
                        ? 'back-cell-inner-item-mobile'
                        : cellIsValid
                        ? 'back-cell-inner-item'
                        : 'back-cell-inner-item-not-valid'
                }
                style={lastCell ? { transform: `translateX(calc((100vw - ${height}px) / 2))`, display: cellAlreadyOpened ? 'none' : null } : { display: cellAlreadyOpened ? 'none' : null }}>
                <source ref={videoRef} src={cellVideo} type='video/mp4' />
            </video>
        </div>
    );
};
